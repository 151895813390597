import { ProfileService } from './profile/profile.service';
import { Component, OnInit } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { LoginService, ILoginObj } from './login/login.service';
import { CommonService } from "../services/common.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthServicesService } from './auth-services.service';
import { ToastController } from '@ionic/angular';
import { PoultryHomePage } from '../app/poultry-home/poultry-home.page';
import { InventoryInfoService } from '../app/inventory-info/inventory-info.service';
import { SqlDbseriveService } from './Dbservices/sql-dbserive.service';
import { OverAllSocketService } from './services/over-all-socket.service';
import { NetworkService, ConnectionStatus } from './services/network.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../app/services/translate-config.service';
import { Events } from './services/events.service';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
import { LayerRetailSupplierService } from './services/layer-retail-supplier.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { RegisterService } from './register/register.service';
import { SupervisorService } from 'src/services/supervisor.service';
import { TraderService } from 'src/services/trader.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  rootPage: any = PoultryHomePage;
  public counter = 0;
  country: string = localStorage.getItem('country');
  logindata: any;
  userRole: string = null;
  retailUserLinkedOrNot: boolean;
  menuSet = {
    '1': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/home/tabs/poultry-home'
          },
          // {
          //   name: 'My Farm',
          //   iconSrc: '../assets/imgs/Farm.svg',
          //   routerLink: '/home/tabs/myfarm'
          // },
          {
            name: 'Inventory',
            iconSrc: '../assets/imgs/Inventory.svg',
            routerLink: '/home/tabs/inventory-info'
          },
          {
            name: 'F.C.R',
            iconSrc: '../assets/imgs/F.C.R.svg',
            routerLink: '/home/tabs/fcr'
            // routerLink: '/fcr'
          }, {
            name: "linkedcontacts",
            iconSrc: "../assets/contact.svg",
            routerLink: "/linked-contacts"
          },
          {
            name: 'autoDevice',
            iconSrc: '../assets/imgs/add-circle-outline.svg',
            routerLink: "/devicesdashboard"
          },
          {
            name: 'EcDevice',
            iconSrc: '../assets/imgs/add-circle-outline.svg',
            routerLink: "/over-view-farms"
          },

          {
            name: 'EcDeviceNew',
            iconSrc: '../assets/imgs/add-circle-outline.svg',
            routerLink: "/ec-automate"
          },
          // {
          //   name: 'EcDeviceGraph',
          //   iconSrc: '../assets/imgs/add-circle-outline.svg',
          //   routerLink: "/ec-farm-graph-view"
          // }
        ]
      },
      {
        header: {
          name: 'Feed'
        },
        items: [
          {
            name: 'feedFrmla',
            iconSrc: '../assets/feed formula-01.svg',
            routerLink: '/layerfarmer/feedFrmla'
          },
          {
            name: 'fdSystem',
            iconSrc: '../assets/Group 1486.svg',
            routerLink: '/layerfarmer/lyrFrmrFeedSck'
          },
          {
            name: 'pastinventory',
            iconSrc: '../assets/pastinventory.svg',
            routerLink: '/farmerolddata'
          },
        ]
      },
      {
        header: {
          name: 'Price'
        },
        items: [
          {
            name: 'Market Indicators',
            iconSrc: '../assets/imgs/Market indicators.svg',
            routerLink: '/market-indicators'
          },
          {
            name: 'Expenses',
            iconSrc: '../assets/imgs/Statistics.svg',
            routerLink: '/statisticks'
          },
          // {
          //   name: 'Trader Marketing',
          //   iconSrc: '../assets/imgs/teamwork.svg',

          // },
          {
            name: 'Liftings',
            iconSrc: '../assets/imgs/liftingvans.png',
            routerLink: '/home/tabs/liftings'
          },
        ]
      },
      {
        header: {
          name: 'Dealers'
        },
        items: [
          {
            name: 'Dealers',
            iconSrc: '../assets/imgs/Rice Husk.svg',
            routerLink: '/ricehusk',
            router_data: 4
          },
        ]
      },
      {
        header: {
          name: null
        },
        items: [
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          // {
          //   name: 'Shop',
          //   iconSrc: '../assets/imgs/shop.svg',
          //   // routerLink: "/products/list"
          // },
        ],
      }
    ],

    '3': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/supervisor'
          },
          {
            name: "linkedcontacts",
            iconSrc: "../assets/contact.svg",
            routerLink: "/linked-contacts"
          },
          // {
          //   name: 'EcDevice',
          //   iconSrc: '../assets/imgs/add-circle-outline.svg',
          //   routerLink: "/over-view-farms"
          // },
          //  {
          //   name: 'My Farms',
          //   iconSrc: '../assets/imgs/Farm.svg',
          //   routerLink: '/home/tabs/myfarm'
          // }, {
          //   name: 'Inventory',
          //   iconSrc: '../assets/imgs/Inventory.svg',
          //   routerLink: '/home/tabs/inventory-info'
          // }, 
          // {
          //   name: 'F.C.R',
          //   iconSrc: '../assets/imgs/F.C.R.svg',
          //   routerLink: '/home/tabs/fcr'
          // }
        ]
      },
      // {
      //   header: null,
      //   items: [
      //     //   {
      //     //   name: 'Add Farmer',
      //     //   iconSrc: '../assets/imgs/add-circle-outline.svg',
      //     //   routerLink: '/addfarmer-supervisor'
      //     // },
      //     // {
      //     //   name: 'DC\'s',
      //     //   iconSrc: '../assets/imgs/ic_add_circle_outline_24px.svg',
      //     //   routerLink: '/lifting-dcs'
      //     // },
      //   ]
      // },
      {
        header: null,
        items: [{
          name: 'Market Indicators',
          iconSrc: '../assets/imgs/Market indicators.svg',
          routerLink: '/market-indicators'
        }, {
          name: 'Posting',
          iconSrc: '../assets/imgs/Posting.svg',
          routerLink: '/lease-home'
        }
        ]
      }
    ],

    '9': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/layerfarmer/tabs/home'
          },
          {
            name: 'Inventory',
            iconSrc: '../assets/imgs/Inventory.svg',
            routerLink: '/layerfarmer/tabs/layersinventorysummary'
          },
          {
            name: 'Expenses',
            iconSrc: '../assets/imgs/Statistics.svg',
            routerLink: '/statisticks'
          },
          {
            name: 'Sales',
            iconSrc: '../assets/sales.svg',
            routerLink: '/layers-sales'
          },
          {
            name: 'autoDevice',

            iconSrc: '../assets/imgs/add-circle-outline.svg',
            routerLink: "/devicesdashboard"
          }
          // {
          //   name: 'updtInven',
          //   iconSrc: '',
          //   routerLink: '/layerfarmer/updtInven'
          // },
        ]
      },
      {
        header: {
          name: 'Feed'
        },
        items: [
          {
            name: 'feedFrmla',
            iconSrc: '../assets/feed formula-01.svg',
            routerLink: '/layerfarmer/feedFrmla'
          },
          {
            name: 'fdSystem',
            iconSrc: '../assets/Group 1486.svg',
            routerLink: '/layerfarmer/lyrFrmrFeedSck'
          },
          {
            name: 'pastinventory',
            iconSrc: '../assets/pastinventory.svg',
            routerLink: '/farmerolddata'
          },
        ]
      },
      {
        header: {
          name: 'Price'
        },
        items: [
          {
            name: 'Market Indicators',
            iconSrc: '../assets/imgs/Market indicators.svg',
            routerLink: '/market-indicators'
          },
          // {
          //   name: 'Trader Marketing',
          //   iconSrc: '../assets/imgs/teamwork.svg',
          // },
        ]
      },

      {
        header: {
          name: null
        },
        items: [{
          name: 'Posting',
          iconSrc: '../assets/imgs/Posting.svg',
          routerLink: '/lease-home'
        }, {
          name: 'Shop',
          iconSrc: '../assets/imgs/shop.svg',
          // routerLink: "/products/list"
        }],
      }
    ],

    '2': [
      {
        header: null,
        items: [{
          name: 'Orders',
          iconSrc: '../assets/ordersimage.svg',
          routerLink: '/trader/tabs/orders'
        },
        {
          name: 'Farmers',
          iconSrc: '../assets/imgs/Farm.svg',
          routerLink: '/trader/tabs/trader-home'
          // routerLink: '/trader/tabs/trader-home'
        },
        {
          name: 'Vans',
          iconSrc: '../assets/Lifting.svg',
          // routerLink: '/trader/tabs/lifting'
          routerLink: '/trader/lifting'
        },
        {
          name: 'chicken center',
          routerLink: '/trader/tabs/centers',
          iconSrc: '../assets/23.svg',
        },
        {
          name: "pastorders",
          iconSrc: "../assets/history.svg",
          routerLink: "/trader/tabs/pastorders"
          // routerLink: "/pastorders"
        },
        {
          name: "linkedcontacts",
          iconSrc: "../assets/contact.svg",
          // routerLink: "/trader/tabs/orders/pastorders"
          routerLink: "/linked-contacts"
        }
        ]
      },
      {
        header: null,
        items: [
          {
            name: 'unassigned Sales',
            iconSrc: '../assets/ordersimage.svg',
            // routerLink: '/trader/unassigned-sales'
            routerLink: '/unassigned-sales'
          },
        ]
      },
      {
        header: null,
        items: [
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          // {
          //   name: 'Shop',
          //   iconSrc: '../assets/imgs/shop.svg',
          //   routerLink: '/products/list'
          // },
        ]
      }
    ],

    'dealer': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/dealer/tabs/home'
          },
          {
            name: 'priceSettings',
            iconSrc: '../assets/imgs/Price settings.svg',
            routerLink: '/dealer/tabs/price-settings'
          },
          {
            name: 'myReviews',
            iconSrc: '../assets/imgs/star.svg',
            routerLink: '/dealer/tabs/reviews'
          }
        ]
      },
      {
        header: null,
        items: [
          {
            name: 'Market Indicators',
            iconSrc: '../assets/market.svg',
            routerLink: '/dealer/tabs/market-indicators'
          },
          {
            name: 'Posting',
            iconSrc: '../assets/Posting.svg',
            routerLink: '/dealer/tabs/postings'
          }
        ]
      }
    ],

    'commonrole': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/home/tabs/poultry-home'
          },
        ]
      },
      {
        header: null,
        items: [{
          name: 'Market Indicators',
          iconSrc: '../assets/market.svg',
          routerLink: '/home/tabs/market-indicators'
        },
        {
          name: 'Posting',
          iconSrc: '../assets/Posting.svg',
          routerLink: '/lease-home'
        }
        ]
      }
    ],

    '8': [
      {
        header: null,
        items: [
          //   {
          //   name: 'Orders',
          //   iconSrc: '../assets/ordersimage.svg',
          //   // assets/ordersimage.svg
          //   routerLink: '/trader/tabs/orders'
          //   // routerLink: '/contact-not-add'
          // },
          // {
          //   name: 'Farmers',
          //   iconSrc: '../assets/imgs/Farm.svg',
          //   routerLink: '/trader/tabs/trader-home'
          //   // routerLink: '/trader/tabs/trader-home'
          // },
          // {
          //   name: 'chicken center',
          //   // iconSrc: "../assets/trader/van.svg",
          //   // iconSrc: "../assets/hen.svg",
          //   routerLink: '/trader/tabs/centers',
          //   iconSrc: '../assets/23.svg',
          // },
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/contact-not-add'
          },
          {
            name: 'Vans',
            // iconSrc: "../assets/hen.svg",
            iconSrc: '../assets/Lifting.svg',
            routerLink: '/trader/lifting'
          },
          {
            name: "linkedcontacts",
            iconSrc: "../assets/contact.svg",
            routerLink: "/linked-contacts"
          }
        ]
      },
      {
        header: null,
        items: [
          {
            name: 'unassigned Sales',
            iconSrc: '../assets/ordersimage.svg',
            // routerLink: '/trader/unassigned-sales'
            routerLink: '/unassigned-sales'
          },
        ]
      },
      {
        header: null,
        items: [
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          // {
          //   name: 'Shop',
          //   iconSrc: '../assets/imgs/shop.svg',
          //   routerLink: '/products/list'
          // },

        ]
      }
    ],

    '10': [
      {
        header: null,
        items: [
          {
            name: 'Home',
            iconSrc: '../assets/imgs/Home.svg',
            routerLink: '/chicken-center-home/tabs/chicken-home',
          },
          {
            name: 'traders',
            iconSrc: '../assets/shopping-bag.svg',
            routerLink: 'chicken-center-home/tabs/createorders',
          },
          {
            name: 'pastorders',
            iconSrc: '../assets/history.svg',
            routerLink: 'chicken-center-home/tabs/pastorders'
          },
          {
            name: "linkedcontacts",
            iconSrc: "../assets/contact.svg",
            routerLink: "chicken-center-home/tabs/contacts"
          }
        ]
      },
      {
        header: {
          name: null
        },
        items: [
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          // {
          //   name: 'Shop',
          //   iconSrc: '../assets/imgs/shop.svg',
          //   routerLink: '/products/list'
          // },

        ],
      }
    ],

    '11': [
      {
        header: null,
        items: [{
          name: 'Home',
          iconSrc: '../assets/imgs/Home.svg',
          routerLink: '/layersupervisor'
        }
        ]
      },
      {
        header: null,
        items: [{
          name: 'Market Indicators',
          iconSrc: '../assets/imgs/Market indicators.svg',
          routerLink: '/market-indicators'
        },
        {
          name: 'Posting',
          iconSrc: '../assets/imgs/Posting.svg',
          routerLink: '/lease-home'
        }]
      }
    ],

    '15': [
      {
        header: null,
        items: [
          // remove this after release
          // {
          //   name: 'Home',
          //   iconSrc: '../assets/list.svg',
          //   routerLink: '/home/tabs/poultry-home',
          // },
          // //activate this after release
          {
            name: 'Orders',
            iconSrc: '../assets/list.svg',
            routerLink: '/wholeseller/tabs/seller',
          },
          {
            name: 'Farms',
            iconSrc: '../assets/group_51.svg',
            routerLink: '/wholeseller/tabs/farms',
          },
          {
            name: 'Delivery Vechiles',
            iconSrc: '../assets/local_shipping_24_px_1.svg',
            routerLink: '/wholeseller/tabs/vehicle',
          },
          {
            name: 'Expenses',
            iconSrc: '../assets/account_balance_wallet_24_px_1.svg',
            routerLink: '/wholeseller/tabs/expenses',
          },
          {
            name: 'Customers',
            iconSrc: '../assets/group.svg',
            routerLink: '/wholeseller/customers'
          }
        ]
      },
      {
        // //activate this after release
        // header: {
        //   name: 'Price'
        // },
        items: [{
          name: 'Market Indicators',
          iconSrc: '../assets/imgs/Market indicators.svg',
          routerLink: '/market-indicators'
        }, {
          name: 'Posting',
          iconSrc: '../assets/imgs/Posting.svg',
          routerLink: '/lease-home'
        }, {
          name: 'Shop',
          iconSrc: '../assets/imgs/shop.svg',
          // routerLink: "/products/list"
        }]
      },
    ],

    '18': [
      {
        header: null,
        items: [
          // // remove this after release
          // {
          //   name: 'Home',
          //   iconSrc: '../assets/list.svg',
          //   routerLink: '/home/tabs/poultry-home',
          // },
          // //activate this after release
          {
            name: 'Orders',
            iconSrc: '../assets/list.svg',
            routerLink: '/retailer/tabs/home',
          },
          {
            name: 'Wholeseler',
            iconSrc: '../assets/wholesellers.svg',
            routerLink: '/retailer/tabs/retail-supplier-wholesale',
          },
          {
            name: 'Customers',
            iconSrc: '../assets/group.svg',
            routerLink: '/retailer/tabs/retail-supplier-customers',
          },
          // {
          //   name: 'Expenses',
          //   iconSrc: '../assets/account_balance_wallet_24_px_1.svg',
          //   routerLink: '/retailer/tabs/retail-supplier-expenese',
          // },
        ]
      },
      {
        header: {
          name: 'Price'
        },
        items: [
          {
            name: 'Market Indicators',
            iconSrc: '../assets/imgs/Market indicators.svg',
            routerLink: '/market-indicators'
          },
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          {
            name: 'Shop',
            iconSrc: '../assets/imgs/shop.svg',
            // routerLink: "/products/list"
          }]
      },
    ],

    '17': [
      {
        header: null,
        items: [
          // // remove this after release
          // {
          //   name: 'Home',
          //   iconSrc: '../assets/list.svg',
          //   routerLink: '/home/tabs/poultry-home',
          // },
          //activate this after release
          {
            name: 'Orders',
            iconSrc: 'assets/list.svg',
            routerLink: '/layer-seller/tabs/seller-home',
          },
          {
            name: 'Suppliers',
            iconSrc: '../assets/shipment (2).svg',
            routerLink: '/layer-seller/tabs/suppliers-list',
          },
          {
            name: 'Market Indicators',
            iconSrc: '/assets/imgs/Market indicators.svg',
            routerLink: '/layer-seller/tabs/market-indicators',
          },
          {
            name: 'Expenses',
            iconSrc: '/assets/account_balance_wallet_24_px_1.svg',
            routerLink: '/layer-seller/tabs/seller-expenses',
          },
        ]
      },
      {
        header: {
          name: 'Price'
        },
        items: [
          {
            name: 'Market Indicators',
            iconSrc: '../assets/imgs/Market indicators.svg',
            routerLink: '/market-indicators'
          },
          {
            name: 'Posting',
            iconSrc: '../assets/imgs/Posting.svg',
            routerLink: '/lease-home'
          },
          {
            name: 'Shop',
            iconSrc: '../assets/imgs/shop.svg',
            // routerLink: "/products/list"
          }]
      },
    ],

  };
  phone: string;
  pendingOrdersCount = 0;
  name: string;
  profile_img_id: any;
  private unsubScribe$: Subject<any> = new Subject();
  firstName: any;
  lastName: any;
  fullName: any;
  noImage: string;
  role_idVerify: any;
  address: any;
  bindAddress: any;
  language: boolean;
  unset: any;
  appVersionNumber: string;
  skipDateInDb: string;
  today = new Date().toISOString().split('T')[0];
  showUpdate: boolean = false;
  updateMessage;
  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    protected router: Router,
    // private camera: Camera,
    private authService: AuthServicesService,
    private loginService: LoginService,
    private commonService: CommonService,
    private ProfileService: ProfileService,
    private InventoryInfoService: InventoryInfoService,
    public toastCtrl: ToastController,
    // private dbservice: SqlDbseriveService,
    // private sqidb: SqlDbseriveService,
    // private basicSocket: OverAllSocketService,
    public network: NetworkService,
    public translate: TranslateService,
    private translateChange: TranslateConfigService,
    public events: Events,
    private _location: Location,
    public alertController: AlertController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    public retailServ: LayerRetailSupplierService,
    private traderService: TraderService,
    private supervisor: SupervisorService,
    private appVersion: AppVersion,
    // private CodePush: CodePush,
    protected register: RegisterService
  ) {
    this.platform.ready().then(() => {
      // this.CodePush.sync({}, (progress) => {
      //   console.log(progress, 731);
      // }).subscribe((status) => {
      //   console.log(status, 733)
      //   // if (status == SyncStatus.CHECKING_FOR_UPDATE)
      //   //   alert('checking for update')
      //   // if (status == SyncStatus.DOWNLOADING_PACKAGE)
      //   //   alert('Downloading')
      //   // if (status == SyncStatus.IN_PROGRESS)
      //   //   alert('Inprogress')
      //   // if (status == SyncStatus.INSTALLING_UPDATE)
      //   //   alert('Install ing update')
      //   // if (status == SyncStatus.UP_TO_DATE)
      //   //   alert('App up to date')
      //   // if (status == SyncStatus.UPDATE_INSTALLED)
      //   //   alert('update installed')
      //   // if (status == SyncStatus.ERROR)
      //   //   alert('Error ')
      // });

      this.appVersion.getVersionNumber().then((data: any) => {
        this.register.AppVersion({ version: data, android: this.platform.is("android"), ios: this.platform.is("ios") }).subscribe((data: any) => {
          console.log(data.msg, "data");
          if (data.success) {
            this.showUpdate = false;
            this.updateMessage = data.msg
          } else {
            this.updateMessage = data.msg;
            if (localStorage.getItem('u_id')) {
              this.ProfileService.getUpdateDate({ u_id: localStorage.getItem("u_id") }).subscribe((res => {
                if (res.success) {
                  this.skipDateInDb = res.msg.split('T')[0];
                  this.compareDates();
                } else {
                  this.updateAlert(this.updateMessage)
                }
              }))
            } else {
              console.log('unable to verify user skip opation preferance');
            }
          }
        })
        localStorage.setItem('appVersion', this.appVersionNumber);
      });
    });


    this.retailServ.makeBreakExp$.subscribe((data: any) => {
      this.retailUserLinkedOrNot = data;
      if (localStorage.getItem('role_id') == '18') {
        if (this.retailUserLinkedOrNot) {
          this.menuSet['18'][0].items.pop();
        } else {
          this.menuSet['18'][0].items.push(
            {
              name: 'Expenses',
              iconSrc: '../assets/account_balance_wallet_24_px_1.svg',
              routerLink: '/retailer/tabs/retail-supplier-expenese',
            },
          )
        }
      }
    });

    this.country = localStorage.getItem('country');
    this.commonService.profileUpdate$.pipe(takeUntil(this.unsubScribe$)).subscribe(result => {
      this.initial();
    });

    this.splashScreen.show();
    this.initializeApp();

    translate.addLangs(['en', 'te', 'ta', 'hi', 'ka', 'ma']);
    this.translateChange.setLanguage('en');
    this.menu.enable(false);
    let role = localStorage.getItem('role_id');
    if (role) {
      this.userRole = ['4', '5', '6', '7', '14', '19', 14, 19].includes(role) ? 'dealer' : role;
    }

    this.loginService.loginSubject$.subscribe((loginData: ILoginObj) => {
      this.logindata = loginData.data[0];
      let role = '' + this.logindata.role_Id;
      this.phone = this.logindata.phone;
      this.fullName = this.logindata.name.charAt(0);
      this.userRole = ['4', '5', '6', '7', '14', '19', 14, 19].includes(role) ? 'dealer' : ('' + role);
      this.profile_img_id = (this.logindata.profile_img_id == undefined) ? false : (this.logindata.profile_img_id);
      this.menu.enable(true);
    });

    this.loginService.loginSubject$.subscribe((loginData: ILoginObj) => {
      this.role_idVerify = localStorage.getItem('role_id');
      this.logindata = loginData.data[0];
      let role = '' + this.logindata.role_Id;
      this.phone = this.logindata.phone;
      this.firstName = this.logindata.fname;
      this.lastName = this.logindata.lname;
      this.fullName = this.logindata.name.charAt(0);
      this.noImage = this.logindata.name.charAt(0);
      this.name = this.logindata.name;
      this.address = this.logindata.address;
      this.editAddress(this.address)

      if (role) {
        this.userRole = ['4', '5', '6', '7', '14', '19'].includes(role.toString()) ? 'dealer' : ['1', '13'].includes(role.toString()) ? '1' : role;
      }

      let pic = (this.logindata.profile_img_id == undefined) ? false : (this.logindata.profile_img_id)
      this.profile_img_id = (pic).toString();
      this.menu.enable(true);
    });

    this.ProfileService.editdata$.subscribe(data => {
      this.name = data.name;
      this.fullName = data.name;
      this.address = data.address;
      this.editAddress(this.address)
    });

    this.ProfileService.editdata$.subscribe(data => {
      this.noImage = data.name.charAt(0);
    });
    // this.events.subscribe('user:created', (data: any) => {});

    this.events.subscribe('user:created', (data: any) => {
      console.log(data, 910);
      if (data.user) {
        let role = data.user['role_Id'];
        this.userRole = role;
        // this.menuSet[this.userRole];
        // this.events.destroy('user:created');
      }
    });

  }

  getCountry() {
    return localStorage.getItem('country');
  }
  initial() {
    this.firstName = localStorage.getItem('fname');
    this.lastName = localStorage.getItem('lname');
    this.name = localStorage.getItem('name');
    this.phone = localStorage.getItem('phone');
    this.address = localStorage.getItem('address');
    this.country = localStorage.getItem('country');
    this.editAddress(this.address)
    let pic = localStorage.getItem('profile_img_id');
    this.profile_img_id = (pic == 'undefined') ? 'false' : (pic);
  }

  ngOnInit() {
    let role = localStorage.getItem('role_id');
    if (role == "2" || role == "8") {
      this.traderService.unassignedOrders({ skip: 0 }).subscribe((orders: any) => {
        if (orders.success) {
          this.supervisor.orderSales.next(orders);
          this.supervisor.pendingOrderSales$.subscribe((orderCount) => {
            if (orderCount.data.length) {
              this.pendingOrdersCount = orderCount.data.length;
            } else {
              this.pendingOrdersCount = 0;
            }
          });
        }
      })
    }
    this.initial();
    if (role) {
      this.userRole = ['4', '5', '6', '7', '14', '19', 14, 19].includes(role) ? 'dealer' : ['1', '13'].includes(role.toString()) ? '1' : ('' + role);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.show();
      this.statusBar.backgroundColorByHexString('#757575');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.network.onNetworkChange().subscribe((status: ConnectionStatus) => {
      if (status == ConnectionStatus.Online) {
      }
    });

    let role = localStorage.getItem('role_id');
    if (this.authService.isAuthenticate()) {
      if (role == "3") {
        this.splashScreen.hide();
        this.router.navigateByUrl('/supervisor', { replaceUrl: true });
      }
      else if (role == '2') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/trader', { replaceUrl: true });
      } else if (role == '10') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/chicken-center-home', { replaceUrl: true });
      }
      else if (role == '8') {
        this.splashScreen.hide();
        // this.router.navigateByUrl('/trader-van-home', { replaceUrl: true });
        this.router.navigateByUrl('/contact-not-add', { replaceUrl: true });
      } else if (role == '15') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/wholeseller', { replaceUrl: true });
      } else if (role == '18') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/retailer', { replaceUrl: true });
      } else if (role == '17') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/layer-seller', { replaceUrl: true });
        this.splashScreen.hide();
        this.router.navigateByUrl('/layersupervisor', { replaceUrl: true });
      } else if (role == '9') {
        this.splashScreen.hide();
        this.router.navigateByUrl('/layerfarmer', { replaceUrl: true });
      } else if (["4", "5", "6", "7", "14", "19"].includes(role)) {
        this.splashScreen.hide();
        this.router.navigateByUrl('/dealer/tabs/home', { replaceUrl: true });
      } else {
        this.splashScreen.hide();
        this.router.navigateByUrl('/home', { replaceUrl: true });
      }
    } else {
      this.language = localStorage.getItem('lang') && localStorage.getItem('lngCode') ? true : false;
      this.splashScreen.hide();
      if (this.language) {
        this.router.navigateByUrl('/user-authentication', { replaceUrl: true });
      } else {
        this.router.navigateByUrl('language', { replaceUrl: true });
      }
    }


    this.platform.backButton.subscribeWithPriority(10, async (processNextHandler) => {
      //this is for popover controllers
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);
      }
      // this is for modals
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);
      }
      // close side menu
      try {
        const element = await this.menu.getOpen();
        if (element) {
          this.menu.close();
        }
      } catch (error) {
        alert(error.error);
      }

      //this is for pages
      if (this._location.isCurrentPathEqualTo('/user-authentication') || this._location.isCurrentPathEqualTo('/home/tabs/poultry-home') ||
        this._location.isCurrentPathEqualTo('/dealer/tabs/home') || this._location.isCurrentPathEqualTo('/layerfarmer/tabs/home') ||
        this._location.isCurrentPathEqualTo('/layersupervisor')) {
        // Show Exit Alert!
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        this._location.back();
        this.navCtrl.pop();

      }
    });
    this.platform.backButton.subscribeWithPriority(5, () => {
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }

  goto(url: string): void {
    this.menu.close();
  }

  logout() {
    if ([1, 2, 3, 8, 10,].includes(this.role_idVerify)) {
      // this.basicSocket.disconnect();
    }
    this.menu.enable(false);
    this.commonService.myform_clearData();
    this.InventoryInfoService.clearInventoryInfo();
    this.unsubScribe$.complete();
    this.authService.logout();
  }

  editclose() {
    this.router.navigateByUrl('/accountsettings/profile');
    this.menu.close();
  }

  ngOnDestroy(): void {
    this.unsubScribe$.complete();
  }

  editAddress(data) {
    if (data) {
      this.bindAddress = (data.length > 20) ? (data.slice(0, 19) + '...') : (data);
    } else {
      this.bindAddress = this.bindAddress || '';
    }
  }

  routePage() {
    this.menu.close();
  }

  showExitConfirm() {
    this.alertController.create({
      header: this.translate.instant('alertMessages.doYouWantExit'),
      backdropDismiss: false,
      cssClass: 'wholeSellerAlert',
      buttons: [{
        text: this.translate.instant('buttons.cancel'),
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: this.translate.instant('buttons.okay'),
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    }).then(alert => {
      alert.present();
    });
  }

  compareDates() {
    if (this.today == this.skipDateInDb) {
    }
    else {
      this.updateAlert(this.updateMessage);
    }
  }

  updateAlert(data) {
    this.alertController.create({
      header: 'Alert',
      message: data,
      cssClass: 'wholeSellerAlert',
      buttons: [
        // {
        //   text: 'Never',
        //   handler: () => {
        //     window.open('https://play.google.com/store/apps/details?id=com.poultryapp.www')
        //   }
        // },
        {
          text: 'Skip',
          handler: () => {
            console.log('pressed on skip');
            this.ProfileService.updateSkipDate({ u_id: localStorage.getItem("u_id") }).subscribe((res) => {
              console.log(res)
            })
          }
        },
        {
          text: 'Update',
          handler: () => {
            if (this.platform.is('android')) {
              window.open('market://details?id=com.poultryapp.www')
            } else {
              window.open('itms-apps://itunes.apple.com/app/poultryapp/id1479288203')
            }
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }
}