import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from "rxjs/operators";
import { AuthServicesService } from 'src/app/auth-services.service';
import { config } from 'src/app/helper/config';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authrizationAdding(req.url);


    if (this.authrizationAdding(req.url)) {
      req = req.clone({
        setHeaders: {
          Authorization: `${localStorage.getItem('token')}`,
          headers: 'Content-Type, application/json',
          deviceId: localStorage.getItem("deviceId") || '',
          // deviceId: '39962471693180'
        }
      })
    } else {
      req = req.clone({
        setHeaders: {
          Authorization: "Basic dmFtc2k5NzM6TW9uc3RlckA5NzM=",
          headers: 'Content-Type, application/json',
          // deviceId: '39962471693180'
        }
      })
    }
    return next.handle(req).pipe(
      tap(ele => {
        // console.log(ele,186354)
        if (ele instanceof HttpResponse) {
          // ele.body.msg == "Logout and login in once again"
          if (ele.body && ele.body.msg == "Invalid Authorization!" || ele.body.msg == "Logout and login in once again") {
            console.log("invalid");
            // alert("inavlid authentication");
            this.auth.logout();
            localStorage.clear();
            this.router.navigateByUrl('/user-authentication');
          } else {
            // console.log("valid request");
          }
        }
      })
    );
  }
  constructor(
    private router: Router,
    public auth: AuthServicesService) {

  }

  authrizationAdding(url) {
    const validUrls = [
      config.api,
      'https://backoffice.poultryapp.com/api',
      'https://backoffice.poultryapp.com/backup_api',
      'http://localhost:3000/api/',
      'http://localhost:3000/backup_api/',
      'http://localhost:2700/api',
    ];

    return validUrls.some(validUrl => url.startsWith(validUrl));
  }
}
